@import "//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css";
:root.light {
  --ql-accent: #f52388;
  --ql-accent-variant: #cc2073;
  --ql-on-accect-primary: #fde8f2;
  --ql-on-accect-secondary: #fbbcdb;
  --ql-surface: #fefefe;
  --ql-surface-variant: #f2f2f2;
  --ql-surface-container1: #f2f2f2;
  --ql-surface-container2: #e2e2e2;
  --ql-surface-on-dimmed1: #fefefe;
  --ql-surface-on-dimmed2: #fefefe;
  --ql-on-surface-primary: #242424;
  --ql-on-surface-secondary: #565656;
  --ql-on-surface-tertiary: #898989;
  --ql-on-surface-lowest: #b1b1b1;
  --ql-on-surface-outline: #c1c1c1;
  --ql-on-surface-separator: #e2e2e2;
  --ql-on-surface-separator2: #00000040;
  --ql-state-layer1: #00000012;
  --ql-state-layer2: #00000026;
  --ql-caution: #d5342b;
  --ql-caution-container: #fec3c0;
  --ql-dimmed1: #00000059;
  --ql-dimmed2: #0009;
}

:root.dark {
  --ql-accent: #f52388;
  --ql-accent-variant: #f64b9d;
  --ql-on-accect-primary: #fde8f2;
  --ql-on-accect-secondary: #fbbcdb;
}

* {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Spoqa Han Sans Neo, sans-serif;
}

html, body {
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

ul, li {
  list-style: none;
}

a {
  color: #000;
  text-decoration: none;
}

a:active {
  color: #000;
}

button {
  cursor: pointer;
}

h2 {
  font-size: 24px;
  font-weight: 700;
}

h3 {
  color: var(--ql-on-surface-secondary);
  font-size: 16px;
  font-weight: 700;
}

.w-full {
  width: 100%;
}

.hidden {
  display: none;
}

header.header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 16px;
  display: flex;
}

header.header .header__logo {
  cursor: pointer;
  align-items: center;
  display: flex;
}

header.header .header__logo_symbol img {
  width: 32px;
  font-size: 0;
}

header.header .header__logo_wordmark {
  margin-left: 10px;
}

header.header .header__logo_wordmark img {
  height: 28px;
}

header.header .header__logo_wordmark div.header__sub_text {
  padding-left: 4px;
  font-size: 11px;
  transform: translateY(-8px);
}

header.header #logout {
  background-color: #0000;
  border: none;
  width: 40px;
}

header.header #logout img {
  width: 24px;
}

table.table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
}

table.table thead tr {
  background-color: #f2f2f2;
  height: 40px;
  position: sticky;
  top: 0;
}

table.table thead tr th {
  text-align: left;
  color: #454545;
  border-bottom: 1px solid #e2e2e2;
  padding: 6px 16px 0;
  font-size: 12px;
  font-weight: 500;
}

table.table tbody {
  background-color: #fff;
}

table.table tbody tr td {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 16px;
}

table.table tbody tr:hover {
  background-color: #fafafa;
}

table.table tbody tr.table__empty_row {
  color: #0000004d;
  background-color: #0000;
  height: 80px;
}

table.table tbody tr.table__empty_row td {
  border-color: #0000;
  height: 100%;
}

table.table .align-right {
  text-align: right;
}

.divider {
  border: 1px solid #e2e2e2;
  width: 100%;
  height: 1px;
  margin: 16px 0;
}

.table__header {
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  display: flex;
}

.table__header_title {
  align-items: center;
  display: flex;
}

.stack {
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  margin-top: 10px;
  overflow: auto;
}

.stack.ll_finish {
  flex: 1;
}

.space-x-1 > * + * {
  margin-left: 4px;
}

.space-y-1 > * + * {
  margin-top: 4px;
}

.space-x-2 > * + * {
  margin-left: 8px;
}

.space-y-2 > * + * {
  margin-top: 8px;
}

.space-x-3 > * + * {
  margin-left: 12px;
}

.space-y-3 > * + * {
  margin-top: 12px;
}

.space-x-4 > * + * {
  margin-left: 16px;
}

.space-y-4 > * + * {
  margin-top: 16px;
}

.space-x-5 > * + * {
  margin-left: 20px;
}

.space-y-5 > * + * {
  margin-top: 20px;
}

.space-x-6 > * + * {
  margin-left: 24px;
}

.space-y-6 > * + * {
  margin-top: 24px;
}

.space-x-7 > * + * {
  margin-left: 28px;
}

.space-y-7 > * + * {
  margin-top: 28px;
}

.space-x-8 > * + * {
  margin-left: 32px;
}

.space-y-8 > * + * {
  margin-top: 32px;
}

.space-x-9 > * + * {
  margin-left: 36px;
}

.space-y-9 > * + * {
  margin-top: 36px;
}

button.ql__button {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 10px;
  font-weight: 500;
}

button.ql__button.x-large {
  height: 58px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 18px;
}

button.ql__button.large {
  height: 48px;
  padding-left: 18px;
  padding-right: 18px;
  font-size: 16px;
}

button.ql__button.small {
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 12px;
}

button.ql__button.medium {
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
}

button.ql__button.fill {
  color: #fde8f2;
  background-color: #f52388;
}

button.ql__button.fill:hover {
  background-color: #ea0b76;
}

button.ql__button.fill:active {
  color: #fbbcdb;
  background-color: #c30962;
}

button.ql__button.fill:disabled {
  color: #b1b1b1;
  cursor: default;
  background-color: #00000012;
}

button.ql__button.gray {
  color: #565656;
  background-color: #f2f2f2;
}

button.ql__button.gray:hover {
  background-color: #e0e0e0;
}

button.ql__button.gray:active {
  color: #898989;
  background-color: #ccc;
}

button.ql__button.gray:disabled {
  color: #b1b1b1;
  cursor: default;
  background-color: #00000012;
}

button.ql__button.outline {
  color: #565656;
  border-color: #c1c1c1;
}

button.ql__button.outline:hover {
  background-color: #ededed;
}

button.ql__button.outline:active {
  color: #898989;
  background-color: #d9d9d9;
}

button.ql__button.outline:disabled {
  color: #b1b1b1;
  cursor: default;
  background-color: #0000;
}

button.ql__button.none {
  color: #565656;
  padding: 0 4px;
}

button.ql__button.none:hover {
  background-color: #ededed;
}

button.ql__button.none:active {
  color: #898989;
  background-color: #d9d9d9;
}

button.ql__button.none:disabled {
  color: #b1b1b1;
  cursor: default;
  background-color: #0000;
}

label.ql__checkbox {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
}

label.ql__checkbox input {
  display: none;
}

label.ql__checkbox input:checked + .ql__checkbox_warpper .ql__checkbox_square {
  background-color: #f52388;
  border-color: #0000;
}

label.ql__checkbox input:checked + .ql__checkbox_warpper .ql__checkbox_square:after {
  opacity: 1;
}

label.ql__checkbox input:checked + .ql__checkbox_warpper .ql__checkbox_label {
  color: #f52388;
}

label.ql__checkbox .ql__checkbox_warpper {
  align-items: center;
  display: inline-flex;
}

label.ql__checkbox .ql__checkbox_square {
  border: 1px solid #636363;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  transition: all .25s;
  position: relative;
}

label.ql__checkbox .ql__checkbox_square:after {
  content: "";
  opacity: 0;
  border: 2px solid #fff;
  border-width: 2px 2px 0 0;
  width: 8px;
  height: 4px;
  transition: all .25s;
  position: absolute;
  top: 2px;
  left: 2px;
  transform: rotateZ(134deg);
}

label.ql__checkbox .ql__checkbox_label {
  letter-spacing: -.4px;
  color: #636363;
  margin-left: 7px;
  font-size: 14px;
  transition: all .25s;
}

input.ql__textfield {
  border: 1px solid var(--ql-on-surface-outline);
  height: 44px;
  color: var(--ql-on-surface-primary);
  border-radius: 8px;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 500;
}

input.ql__textfield.no-border {
  border-color: #0000;
}

input.ql__textfield::placeholder {
  color: var(--ql-on-surface-lowest);
  font-weight: 400;
}

.ql__textfield_warpper .ql__textfield_status {
  color: var(--ql-on-surface-tertiary);
  margin-top: 4px;
  padding-left: 8px;
  font-size: 11px;
}

.ql__textfield_warpper.status-error input.ql__textfield {
  border-color: var(--ql-caution);
}

.ql__textfield_warpper.status-error .ql__textfield_status {
  color: var(--ql-caution);
}

.ql__searchbar {
  background-color: var(--ql-surface-container1);
  border-radius: 20px;
  align-items: center;
  height: 36px;
  display: flex;
  position: relative;
}

.ql__searchbar.w-full {
  width: 100%;
}

.ql__searchbar .ql__searchbar_symbol {
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 36px;
  display: flex;
}

.ql__searchbar .ql__searchbar_symbol img {
  width: 16px;
}

.ql__searchbar input.ql__searchbar_input {
  background-color: #0000;
  border: none;
  width: 100%;
  height: 36px;
  padding-left: 0;
  padding-right: 16px;
  font-size: 12px;
}

.ql__searchbar input.ql__searchbar_input.valid {
  padding-right: 42px;
}

.ql__searchbar input.ql__searchbar_input.valid + .ql__searchbar_clear {
  display: block;
}

.ql__searchbar button.ql__searchbar_clear {
  z-index: 1;
  background-color: #0000;
  border: none;
  width: 20px;
  height: 20px;
  display: none;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

.ql__searchbar button.ql__searchbar_clear img {
  width: 100%;
}

.ql__confirm {
  z-index: 10010;
  opacity: 0;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  transition: all .25s;
  display: flex;
  position: fixed;
  inset: 0;
}

.ql__confirm.open {
  opacity: 1;
  visibility: visible;
}

.ql__confirm_background {
  z-index: 10011;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ql__confirm_body {
  z-index: 10012;
  background-color: #fff;
  border-radius: 10px;
  min-width: 320px;
  max-width: 600px;
  position: relative;
  overflow: hidden;
}

.ql__confirm_body .confirm__title {
  text-align: center;
  height: 46px;
  padding-top: 24px;
  font-weight: 500;
}

.ql__confirm_body .confirm__message {
  word-break: break-all;
  text-align: center;
  flex: 1;
  padding: 18px 24px 24px;
  font-size: 14px;
}

.ql__confirm_body .confirm__buttons {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.ql__confirm_body .confirm__buttons button {
  border: none;
  flex: 1;
  height: 46px;
}

.ql__confirm_body .confirm__buttons button.confirm__buttons_confirm {
  color: #fde8f2;
  background-color: #f52388;
  font-weight: 500;
}

@keyframes rotate {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.ql__progress {
  width: var(--stroke);
  height: var(--stroke);
  border-style: solid;
  border-color: #f52388 #f52388 #f52388 #fbbcdb;
  border-radius: 50%;
  animation: 1s ease-out infinite rotate;
}

.ql__progress.thin {
  border-width: calc(var(--stroke) / 6);
}

.ql__progress.thick {
  border-width: calc(var(--stroke) / 4);
}

.ql__modal {
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transition: all .25s;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.ql__modal.open {
  opacity: 1;
  visibility: visible;
}

.ql__modal .ql__modal_background {
  z-index: 10001;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ql__modal .ql__modal_stack {
  width: 100%;
  height: 100%;
  padding: 40px 0;
  overflow: auto;
}

.ql__modal .ql__modal_body {
  z-index: 10002;
  background-color: #fff;
  border-radius: 10px;
  min-width: 320px;
  margin: 0 auto;
  padding: 36px;
  position: relative;
  overflow: hidden;
}

.ql__modal .ql__modal_header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  display: flex;
  position: relative;
}

.ql__modal .ql__modal_header:after {
  content: "";
  background-color: #f52388;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
}

.ql__modal .ql__modal_header .ql__modal_title {
  font-size: 20px;
  font-weight: bold;
}

label.ql__switch {
  display: inline-flex;
}

label.ql__switch input[type="checkbox"] {
  display: none;
}

label.ql__switch input[type="checkbox"]:checked + .ql__switch_box {
  background-color: #f52388;
}

label.ql__switch input[type="checkbox"]:checked + .ql__switch_box .ql__switch_ball {
  background-color: #fff;
  left: 19px;
}

label.ql__switch .ql__switch_box {
  cursor: pointer;
  background-color: #00000040;
  border-radius: 12px;
  width: 40px;
  height: 24px;
  transition: all .25s;
  position: relative;
}

label.ql__switch .ql__switch_ball {
  background-color: #565656;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  transition: all .25s;
  position: absolute;
  top: 3px;
  left: 3px;
}
/*# sourceMappingURL=index.4123da8b.css.map */

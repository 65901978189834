@use "sass:color";
@use "../theme.scss" as theme;

button.ql__button {
  border: 1px solid transparent;
  border-radius: 10px;
  font-weight: 500;
  background-color: transparent;

  &.x-large {
    height: 58px;
    padding-right: 24px;
    padding-left: 24px;
    font-size: 18px;
  }

  &.large {
    height: 48px;
    padding-right: 18px;
    padding-left: 18px;
    font-size: 16px;
  }

  &.small {
    height: 32px;
    font-size: 12px;
    padding-right: 12px;
    padding-left: 12px;
  }

  &.medium {
    height: 40px;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 14px;
  }

  &.fill {
    color: theme.$on-accect-primary;
    background-color: theme.$accent;

    &:hover {
      background-color: color.adjust(theme.$accent, $lightness: -7%);
    }

    &:active {
      color: theme.$on-accect-secondary;
      background-color: color.adjust(theme.$accent, $lightness: -15%);
    }

    &:disabled {
      color: theme.$on-surface-lowest;
      background-color: theme.$state-layer1;
      cursor: default;
    }
  }

  &.gray {
    color: theme.$on-surface-secondary;
    background-color: theme.$surface-container1;

    &:hover {
      background-color: color.adjust(
        theme.$surface-container1,
        $lightness: -7%
      );
    }

    &:active {
      color: theme.$on-surface-tertiary;
      background-color: color.adjust(
        theme.$surface-container1,
        $lightness: -15%
      );
    }

    &:disabled {
      color: theme.$on-surface-lowest;
      background-color: theme.$state-layer1;
      cursor: default;
    }
  }

  &.outline {
    color: theme.$on-surface-secondary;
    border-color: theme.$on-surface-outline;

    &:hover {
      background-color: color.adjust(white, $lightness: -7%);
    }

    &:active {
      color: theme.$on-surface-tertiary;
      background-color: color.adjust(white, $lightness: -15%);
    }

    &:disabled {
      color: theme.$on-surface-lowest;
      background-color: transparent;
      cursor: default;
    }
  }

  &.none {
    color: theme.$on-surface-secondary;
    padding: 0 4px;

    &:hover {
      background-color: color.adjust(white, $lightness: -7%);
    }

    &:active {
      color: theme.$on-surface-tertiary;
      background-color: color.adjust(white, $lightness: -15%);
    }

    &:disabled {
      color: theme.$on-surface-lowest;
      background-color: transparent;
      cursor: default;
    }
  }
}

@use "../theme.scss" as theme;

input.ql__textfield {
  height: 44px;
  padding: 0 12px;
  border: 1px solid var(--ql-on-surface-outline);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: var(--ql-on-surface-primary);

  &.no-border {
    border-color: transparent;
  }

  &::placeholder {
    color: var(--ql-on-surface-lowest);
    font-weight: 400;
  }
}

.ql__textfield_warpper {
  .ql__textfield_status {
    color: var(--ql-on-surface-tertiary);
    font-size: 11px;
    padding-left: 8px;
    margin-top: 4px;
  }

  &.status-error {
    input.ql__textfield {
      border-color: var(--ql-caution);
    }

    .ql__textfield_status {
      color: var(--ql-caution);
    }
  }
}

@use "../theme.scss" as theme;

.ql__confirm {
  position: fixed;
  z-index: 10010;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  &_background {
    position: absolute;
    z-index: 10011;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: theme.$ql-black-050;
  }

  &_body {
    position: relative;
    z-index: 10012;
    min-width: 320px;
    max-width: 600px;
    border-radius: 10px;
    background-color: white;
    overflow: hidden;

    .confirm {
      &__title {
        padding-top: 24px;
        height: 46px;
        text-align: center;
        font-weight: 500;
      }

      &__message {
        flex: 1;
        padding: 18px 24px 24px 24px;
        font-size: 14px;
        word-break: break-all;
        text-align: center;
      }

      &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        button {
          flex: 1;
          height: 46px;
          border: none;

          &.confirm__buttons {
            &_confirm {
              background-color: theme.$accent;
              color: theme.$on-accect-primary;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

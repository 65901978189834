@use "../theme.scss" as theme;

label.ql__switch {
  display: inline-flex;

  input[type="checkbox"] {
    display: none;

    &:checked + .ql__switch_box {
      background-color: theme.$accent;

      .ql__switch_ball {
        left: calc(40px - 18px - 3px);
        background-color: white;
      }
    }
  }

  .ql__switch_box {
    position: relative;
    width: 40px;
    height: 24px;
    border-radius: 12px;
    background-color: theme.$on-surface-separator2;

    transition: all 0.25s;
    cursor: pointer;
  }

  .ql__switch_ball {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: theme.$on-surface-secondary;
    transition: all 0.25s;
  }
}

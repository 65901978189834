@use "../theme.scss" as theme;

@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.ql__progress {
  width: var(--stroke);
  height: var(--stroke);
  border-radius: 50%;
  border-style: solid;
  border-color: theme.$accent theme.$accent theme.$accent
    theme.$on-accect-secondary;
  animation: rotate 1s infinite ease-out;

  &.thin {
    border-width: calc(var(--stroke) / 6);
  }

  &.thick {
    border-width: calc(var(--stroke) / 4);
  }
}

@use "sass:color";
@use "theme.scss" as theme;

@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

:root.light {
  --ql-accent: #{theme.$ql-primary-050};
  --ql-accent-variant: #{theme.$ql-primary-040};
  --ql-on-accect-primary: #{theme.$ql-primary-095};
  --ql-on-accect-secondary: #{theme.$ql-primary-085};

  // surface
  --ql-surface: #{theme.$ql-gray-095};
  --ql-surface-variant: #{theme.$ql-gray-090};
  --ql-surface-container1: #{theme.$ql-gray-090};
  --ql-surface-container2: #{theme.$ql-gray-085};
  --ql-surface-on-dimmed1: #{theme.$ql-gray-095};
  --ql-surface-on-dimmed2: #{theme.$ql-gray-095};
  --ql-on-surface-primary: #{theme.$ql-gray-010};
  --ql-on-surface-secondary: #{theme.$ql-gray-025};
  --ql-on-surface-tertiary: #{theme.$ql-gray-050};
  --ql-on-surface-lowest: #{theme.$ql-gray-070};
  --ql-on-surface-outline: #{theme.$ql-gray-075};
  --ql-on-surface-separator: #{theme.$ql-gray-085};
  --ql-on-surface-separator2: #{theme.$ql-black-025};

  // state layer
  --ql-state-layer1: #{theme.$ql-black-007};
  --ql-state-layer2: #{theme.$ql-black-015};

  // caution
  --ql-caution: #{theme.$ql-red-040};
  --ql-caution-container: #{theme.$ql-red-085};

  // dimmed
  --ql-dimmed1: #{theme.$ql-black-035};
  --ql-dimmed2: #{theme.$ql-black-060};
}

:root.dark {
  --ql-accent: #{theme.$ql-primary-050};
  --ql-accent-variant: #{theme.$ql-primary-060};
  --ql-on-accect-primary: #{theme.$ql-primary-095};
  --ql-on-accect-secondary: #{theme.$ql-primary-085};
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Spoqa Han Sans Neo", sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: theme.$ql-gray-090;
}

ul,
li {
  list-style: none;
}

a {
  color: black;
  text-decoration: none;

  &:active {
    color: black;
  }
}

button {
  cursor: pointer;
}

h2 {
  font-size: 24px;
  font-weight: 700;
}

h3 {
  font-size: 16px;
  font-weight: 700;
  color: var(--ql-on-surface-secondary);
}

.w-full {
  width: 100%;
}

.hidden {
  display: none;
}

header.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 16px;

  .header__logo {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_symbol img {
      width: 32px;
      font-size: 0px;
    }

    &_wordmark {
      margin-left: 10px;

      img {
        height: 28px;
      }

      div.header__sub_text {
        padding-left: 4px;
        font-size: 11px;
        transform: translateY(-8px);
      }
    }
  }

  #logout {
    border: none;
    background-color: transparent;
    width: 40px;

    img {
      width: 24px;
    }
  }
}

table.table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;

  thead tr {
    position: sticky;
    top: 0;
    height: 40px;
    background-color: theme.$ql-gray-090;

    th {
      font-size: 12px;
      font-weight: 500;
      text-align: left;
      color: theme.$ql-gray-020;
      padding: 6px 16px 0 16px;
      border-bottom: 1px solid theme.$on-surface-separator1;
    }
  }

  tbody {
    background-color: white;

    tr {
      td {
        padding: 10px 16px;
        border-bottom: 1px solid theme.$ql-gray-085;
      }

      &:hover {
        background-color: color.adjust(#fff, $lightness: -2%);
      }
    }

    tr.table__empty_row {
      height: 80px;
      color: theme.$ql-black-030;
      background-color: transparent;

      td {
        height: 100%;
        border-color: transparent;
      }
    }
  }

  .align-right {
    text-align: right;
  }
}

.divider {
  width: 100%;
  height: 1px;
  margin: 16px 0;
  border: 1px solid theme.$on-surface-separator1;
}

.table__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  background-color: white;

  &_title {
    display: flex;
    align-items: center;
  }
}

.stack {
  margin-top: 10px;
  border-radius: 6px;
  border: 1px solid theme.$ql-gray-085;
  overflow: auto;

  &.ll_finish {
    flex: 1;
  }
}

@for $i from 1 to 10 {
  .space-x-#{$i} {
    & > * + * {
      margin-left: #{$i * 4}px;
    }
  }

  .space-y-#{$i} {
    & > * + * {
      margin-top: #{$i * 4}px;
    }
  }
}

.ql__searchbar {
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 20px;
  background-color: var(--ql-surface-container1);

  &.w-full {
    width: 100%;
  }

  .ql__searchbar_symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 36px;

    img {
      width: 16px;
    }
  }

  input.ql__searchbar_input {
    width: 100%;
    height: 36px;
    padding-left: 0;
    padding-right: 16px;
    font-size: 12px;
    border: none;
    background-color: transparent;

    &.valid {
      padding-right: 42px;

      & + .ql__searchbar_clear {
        display: block;
      }
    }
  }

  button.ql__searchbar_clear {
    display: none;
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 14px;
    width: 20px;
    height: 20px;
    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    img {
      width: 100%;
    }
  }
}

@use "../theme.scss" as theme;

label.ql__checkbox {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  input {
    display: none;

    &:checked + .ql__checkbox_warpper {
      .ql__checkbox_square {
        border-color: transparent;
        background-color: theme.$accent;

        &:after {
          opacity: 1;
        }
      }

      .ql__checkbox_label {
        color: theme.$accent;
      }
    }
  }

  .ql__checkbox_warpper {
    display: inline-flex;
    align-items: center;
  }

  .ql__checkbox_square {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #636363;
    transition: all 0.25s;

    &:after {
      content: "";
      opacity: 0;
      position: absolute;
      border: 1px solid #fff;
      border-width: 2px 2px 0 0;
      width: 8px;
      height: 4px;
      top: 2px;
      left: 2px;
      transform: rotateZ(134deg);
      transition: all 0.25s;
    }
  }

  .ql__checkbox_label {
    margin-left: 7px;
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #636363;
    transition: all 0.25s;
  }
}

@use "../theme.scss" as theme;

.ql__modal {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  .ql__modal_background {
    position: absolute;
    z-index: 10001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: theme.$ql-black-050;
  }

  .ql__modal_stack {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 40px 0;
  }

  .ql__modal_body {
    position: relative;
    z-index: 10002;
    min-width: 320px;
    border-radius: 10px;
    padding: 36px;
    background-color: white;
    overflow: hidden;
    margin: 0 auto;
  }

  .ql__modal_header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 16px;

    &:after {
      content: "";
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0;
      background-color: theme.$accent;
    }

    .ql__modal_title {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
